import differenceInDays from 'date-fns/differenceInDays'
import {filter, compose, map, join} from '@cullylarson/f'
import {notEmpty} from '@@client/lib/util'

export const getPartSummary = partInfo => `${partInfo.modelYear} ${partInfo.makeName} ${partInfo.carlineName} ${partInfo.partTypeName}`

export const checkIsNew = (inventoriedDateStr, goBackDays = 30) => {
    if(!inventoriedDateStr) return false

    const inventoriedDate = new Date(inventoriedDateStr)

    return Math.abs(differenceInDays(new Date(), inventoriedDate)) <= goBackDays
}

export const buildSearchQuery = ({partTypeSlug, year, make, carlineName, application, categoryCode}) => {
    return filter(notEmpty, {
        part: partTypeSlug,
        year,
        make,
        model: carlineName,
        app: application,
        cat: categoryCode,
    })
}

export const hasRetailPrice = retailPrice => Boolean(retailPrice)

export const buildSearchUrl = ({partTypeSlug, year, makeName, carlineName, application}) => {
    return compose(
        x => `/parts?${x}`,
        join('&'),
        map(join('=')),
        Object.entries,
        map(encodeURIComponent),
        filter(notEmpty),
    )({
        part: partTypeSlug,
        year,
        make: makeName,
        model: carlineName,
        app: application,
    })
}

export const isRebuilderSlug = (slug) => {
    return slug === 'rebuilder'
}
