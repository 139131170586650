import * as actionTypes from './action-types'

export const kinds = {
    ERROR: 'error',
    NOTICE: 'notice',
    SUCCESS: 'success',
}

function addNotification(type, msg, kind, {
    allowManualDimiss = true,
    autoDismiss = true,
    dismissTimeout = 10000,
}) {
    return {
        type,
        msg,
        kind,
        options: {
            autoDismiss,
            dismissTimeout,
            allowManualDimiss,
        },
    }
}

export function addNotificationError(msg, options = {}) {
    return addNotification(actionTypes.ADD_NOTIFICATION, msg, kinds.ERROR, options)
}

export function addNotificationNotice(msg, options = {}) {
    return addNotification(actionTypes.ADD_NOTIFICATION, msg, kinds.NOTICE, options)
}

export function addNotificationSuccess(msg, options = {}) {
    return addNotification(actionTypes.ADD_NOTIFICATION, msg, kinds.SUCCESS, options)
}

export function markNotificationShown(id) {
    return {
        type: actionTypes.MARK_NOTIFICATION_SHOWN,
        id,
    }
}

export function removeNotification(id) {
    return {
        type: actionTypes.REMOVE_NOTIFICATION,
        id,
    }
}
